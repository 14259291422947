export enum LeadStatus {
  NEW = 'NEW',
  CONTACTED = 'CONTACTED',
  NEGOTIATION = 'NEGOTIATION',
  COMMITMENT = 'COMMITMENT',
  CLOSED = 'CLOSED',
  PAUSED = 'PAUSED',
}

export enum DealStatus {
  OPEN = 'OPEN',
  PROPOSAL = 'PROPOSAL',
  WON = 'WON',
  LOST = 'LOST',
  PAUSED = 'PAUSED',
}

export type Named = {
  id: string
  name: string
}

export type User = {
  id: string
  name: string
  email: string
  isAdmin: boolean
  isManager: boolean
}

export type NewUser = {
  name: string
  email: string
  isAdmin?: boolean
  isManager?: boolean
}

export type Lead = {
  id: string
  name: string
  email?: string
  phone?: string
  company: string
  website?: string
  address?: string
  jobTitle?: string
  status: LeadStatus
  source?: Named
  owner: Named
}

export type LeadSource = {
  id: string
  name: string
}

export type Opportunity = {
  id: string
  subject: string
  name: string
  email?: string
  phone?: string
  company: string
  website?: string
  address?: string
  owner: Named
  lead?: Named | null
  status: DealStatus
  product: string
  value: string
}

export enum TaskType {
  CALL = 'CALL',
  TASK = 'TASK',
  MEETING = 'MEETING',
}

export enum TaskStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  PAUSED = 'PAUSED',
}

export type Task = {
  id: string
  createdAt: string
  updatedAt: string
  type: TaskType
  subject: string
  description: string
  status: TaskStatus
  time?: string
  dueDate?: string
  owner: Named
  lead?: Named | null
  deal?: Named | null
}

export type LoginInput = {
  email: string
  password: string
}

export type ChangePasswordInput = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

export type AuthResponse = {
  user: User
  token: string
}

export type New<T> = Omit<T, 'id' | 'createdAt' | 'updatedAt' | 'source' | 'owner'>

export type NewLead = New<Lead> & { sourceId?: string }

export type UpdateLead = Partial<Lead>

export type UpdateOpportunity = Partial<Opportunity>

export type NewSource = New<LeadSource>

export type NewOpportunity = Omit<New<Opportunity>, 'lead'> & { leadId?: string }

export type NewTask = {
  type: TaskType
  subject: string
  description?: string
  status: TaskStatus
  time?: string
  dueDate?: string
  leadId?: string
  dealId?: string
  ownerId?: string
}

export type UpdateTask = {
  subject?: string
  description?: string
  status?: TaskStatus
  ownerId?: string
  time?: string
  dueDate?: string
}

export type ListTasksQuery = {
  lead?: string
  deal?: string
  owner?: string
  status?: TaskStatus
}
