import { PublicClientApplication } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useMemo } from 'react'

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: 'f4969f79-b174-4b2e-a018-883bd31a3829',
    authority: 'https://login.microsoftonline.com/zoracom.com',
    redirectUri: `${window.location.origin}/login`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
})

export const loginRequest = {
  scopes: ['User.Read'],
}

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}

export function useMsalAuth() {
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const account = accounts[0]

  return useMemo(
    () => ({
      account,
      instance,
      inProgress,
      isAuthenticated,
      async acquireToken() {
        const request = { ...loginRequest, account }
        try {
          return await instance.acquireTokenSilent(request)
        } catch (e) {
          return await instance.acquireTokenPopup(request)
        }
      },
      login: () => instance.loginPopup(loginRequest),
      logout: () => instance.logoutRedirect({ onRedirectNavigate: () => false }),
    }),
    [account, inProgress, instance, isAuthenticated]
  )
}
