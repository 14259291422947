import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AppState } from './store'
import {
  AuthResponse,
  ChangePasswordInput,
  DealStatus,
  Lead,
  LeadSource,
  LeadStatus,
  ListTasksQuery,
  LoginInput,
  NewLead,
  NewOpportunity,
  NewSource,
  NewTask,
  NewUser,
  Opportunity,
  Task,
  TaskStatus,
  UpdateLead,
  UpdateOpportunity,
  User,
} from './types'

const allTagTypes = ['Lead', 'Deal', 'Source', 'Task', 'User'] as const

export const apiUrl = process.env.REACT_APP_API_URL!!.replace('localhost', window.location.hostname)

export const api = createApi({
  reducerPath: 'api',

  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState() as AppState
      if (auth) {
        headers.set('Authorization', `Bearer ${auth}`)
      }
      return headers
    },
  }),

  tagTypes: allTagTypes,

  endpoints: ({ query, mutation }) => ({
    login: mutation<AuthResponse, LoginInput>({
      query: body => ({ url: '/auth/login', body, method: 'POST' }),
      invalidatesTags: allTagTypes,
    }),

    loginMicrosoft: mutation<AuthResponse, { token: string }>({
      query: body => ({ url: 'auth/microsoft', body, method: 'POST' }),
      invalidatesTags: allTagTypes,
    }),

    changePassword: mutation<void, ChangePasswordInput>({
      query: body => ({ url: 'auth/change-password', body, method: 'POST' }),
    }),

    getMe: query<User, void>({
      query: () => '/users/me',
      providesTags: ['User'],
    }),

    listLeads: query<Lead[], void>({
      query: () => ({ url: '/leads' }),
      providesTags: ['Lead'],
    }),

    listOpenLeads: query<Lead[], void>({
      query: () => ({ url: '/leads', params: { unconverted: true } }),
      providesTags: ['Lead'],
    }),

    listOpportunities: query<Opportunity[], void>({
      query: () => '/deals',
      providesTags: ['Deal'],
    }),

    listSources: query<LeadSource[], void>({
      query: () => '/lead-sources',
      providesTags: ['Source'],
    }),

    listTasks: query<Task[], ListTasksQuery | undefined>({
      query: params => ({ url: '/tasks', params }),
      providesTags: ['Task'],
    }),

    listUsers: query<User[], void>({
      query: () => '/users',
      providesTags: ['User'],
    }),

    createLead: mutation<Lead, NewLead>({
      query: body => ({ url: '/leads', body, method: 'POST' }),
      invalidatesTags: ['Lead'],
    }),

    createOpportunity: mutation<Opportunity, NewOpportunity>({
      query: body => ({ url: '/deals', body, method: 'POST' }),
      invalidatesTags: ['Deal', 'Lead'],
    }),

    createSource: mutation<LeadSource, NewSource>({
      query: body => ({ url: '/leads', body, method: 'POST' }),
      invalidatesTags: ['Source'],
    }),

    createTask: mutation<Task, NewTask>({
      query: body => ({ url: '/tasks', body, method: 'POST' }),
      invalidatesTags: ['Task', 'Deal', 'Lead'],
    }),

    createUser: mutation<User, NewUser>({
      query: body => ({ url: '/users', body, method: 'POST' }),
      invalidatesTags: ['User'],
    }),

    updateLead: mutation<Lead, UpdateLead>({
      query: ({ id, ...body }) => ({ url: `/leads/${id}`, body, method: 'PUT' }),
      invalidatesTags: ['Lead'],
    }),

    updateOpportunity: mutation<Opportunity, UpdateOpportunity>({
      query: ({ id, ...body }) => ({ url: `/deals/${id}`, body, method: 'PUT' }),
      invalidatesTags: ['Deal'],
    }),

    setLeadStatus: mutation<Lead, { id: string; status: LeadStatus }>({
      query: ({ id, ...body }) => ({ url: `/leads/${id}/status`, body, method: 'POST' }),
      invalidatesTags: ['Lead'],
    }),

    setLeadOwner: mutation<Lead, { id: string; ownerId: string }>({
      query: ({ id, ownerId }) => ({
        url: `/leads/${id}/owner`,
        body: { ownerId, transferTasks: true },
        method: 'POST',
      }),
      invalidatesTags: ['Lead', 'Task'],
    }),

    setOpportunityStatus: mutation<Opportunity, { id: string; status: DealStatus }>({
      query: ({ id, ...body }) => ({ url: `/deals/${id}/status`, body, method: 'POST' }),
      invalidatesTags: ['Deal'],
    }),

    setOpportunityOwner: mutation<Lead, { id: string; ownerId: string }>({
      query: ({ id, ownerId }) => ({
        url: `/deals/${id}/owner`,
        body: { ownerId, transferTasks: true },
        method: 'POST',
      }),
      invalidatesTags: ['Deal', 'Task'],
    }),

    updateTask: mutation<Task, { id: string; status?: TaskStatus; ownerId?: string }>({
      query: ({ id, ...body }) => ({ url: `/tasks/${id}`, body, method: 'PATCH' }),
      invalidatesTags: ['Lead', 'Deal', 'Task'],
    }),

    deleteLead: mutation<void, string>({
      query: id => ({ url: `/leads/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Lead', 'Deal'],
    }),

    deleteOpportunity: mutation<void, string>({
      query: id => ({ url: `/opportunities/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Lead', 'Deal'],
    }),

    deleteTask: mutation<void, string>({
      query: id => ({ url: `/tasks/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Task', 'Lead', 'Deal'],
    }),
  }),
})

export const {
  useLoginMutation,
  useLoginMicrosoftMutation,
  useChangePasswordMutation,
  useGetMeQuery,

  useListLeadsQuery,
  useListOpenLeadsQuery,
  useListOpportunitiesQuery,
  useListSourcesQuery,
  useListTasksQuery,
  useListUsersQuery,

  useCreateLeadMutation,
  useCreateOpportunityMutation,
  useCreateSourceMutation,
  useCreateTaskMutation,
  useCreateUserMutation,

  useUpdateLeadMutation,
  useUpdateOpportunityMutation,

  useSetLeadStatusMutation,
  useSetLeadOwnerMutation,
  useSetOpportunityStatusMutation,
  useSetOpportunityOwnerMutation,
  useUpdateTaskMutation,

  useDeleteLeadMutation,
  useDeleteOpportunityMutation,
  useDeleteTaskMutation,
} = api

export function apiErrorMessage(error: any) {
  return error?.data?.message ?? error?.message ?? 'An unknown error occured'
}

export function canModify(
  user: User | null | undefined,
  item: { owner: { id: string } } | null | undefined
) {
  return !!user && !!item && (user.isAdmin || user.isManager || user.id === item.owner.id)
}
