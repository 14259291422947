import {
  ArrowLeftOutlined,
  DownOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Col, Dropdown, Form, MenuProps, Modal, Row, Spin } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import ActivityCard from '../components/ActivityCard'
import ChangeOwnerModal from '../components/ChangeOwnerModal'
import DetailsCard from '../components/DetailsCard'
import { nameToLabel } from '../components/FormInput'
import UserAvatar from '../components/UserAvatar'
import {
  useListLeadsQuery,
  useSetLeadOwnerMutation,
  useSetLeadStatusMutation,
  useUpdateLeadMutation,
} from '../data/api'
import { LeadStatus } from '../data/types'

export default function LeadDetailPage() {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()
  const { data: allLeads, isLoading } = useListLeadsQuery()
  const [submitUpdate, updateRequest] = useUpdateLeadMutation()
  const [submitStatus, statusRequest] = useSetLeadStatusMutation()
  const lead = useMemo(() => allLeads?.find(lead => lead.id === id), [allLeads, id])

  const [changingOwner, setChangingOwner] = useState(false)
  const setOwnerMutation = useSetLeadOwnerMutation()

  const setStatus = async (status: LeadStatus) => {
    if (lead) submitStatus({ id: lead.id, status })
  }

  const statusMenu: MenuProps['items'] = Object.values(LeadStatus).map(status => ({
    key: status,
    label: nameToLabel(status),
    onClick: () =>
      Modal.confirm({
        icon: <InfoCircleOutlined />,
        title: `Change status to ${nameToLabel(status)}?`,
        onOk: () => setStatus(status),
        okButtonProps: { className: 'bg-[#00afef]' },
      }),
  }))

  useEffect(() => {
    if (lead) {
      form.setFieldsValue({
        ...lead,
        sourceName: lead.source?.name,
        sourceId: lead.source?.id,
      })
    }
  }, [form, lead])

  if (isLoading) {
    return <Spin size="large" className="m-auto" />
  }
  if (!lead) {
    return <Navigate to="/leads" replace />
  }

  return (
    <Row className="w-full max-w-screen-xl mx-auto" gutter={16}>
      <Col xs={24} className="flex justify-between items-center mb-4">
        <ChangeOwnerModal
          type="lead"
          mutation={setOwnerMutation}
          itemId={lead?.id}
          visible={changingOwner}
          onClose={() => setChangingOwner(false)}
          currentOwnerId={lead?.owner?.id}
        />
        <div className="flex gap-4 items-center mb-8">
          <ArrowLeftOutlined className="text-2xl cursor-pointer" onClick={() => navigate(-1)} />

          <UserAvatar user={lead} size="large" />
          <div>
            <div className="font-medium text-xl leading-snug">{lead.name}</div>
            <div className="text-sm">Lead</div>
          </div>
        </div>

        <div className="flex items-center">
          <Dropdown
            trigger={['click']}
            menu={{ items: statusMenu }}
            disabled={statusRequest.isLoading}
          >
            <div className="flex items-center px-8 py-1 gap-2 border-r border-gray-300 cursor-pointer">
              <div>
                <div className="font-medium leading-snug">{nameToLabel(lead.status)}</div>
                <div className="text-xs text-gray-400">Status</div>
              </div>
              {statusRequest.isLoading ? (
                <Spin size="small" />
              ) : (
                <DownOutlined className="text-gray-400 ml-2" size={20} />
              )}
            </div>
          </Dropdown>

          <div
            className="flex items-center px-8 py-1 gap-2 border-r border-gray-300 cursor-pointer"
            onClick={() => setChangingOwner(true)}
          >
            <UserAvatar user={lead.owner} />
            <div>
              <div className="font-medium leading-snug">{lead.owner.name}</div>
              <div className="text-xs text-gray-400">Owner</div>
            </div>
            <DownOutlined className="text-gray-400 ml-2" size={20} />
          </div>

          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            menu={{
              items: [
                {
                  key: 'opportunity',
                  label: (
                    <Link to={`/opportunities/create/${lead.id}`}>Convert to opportunity</Link>
                  ),
                },
              ],
            }}
          >
            <div className="flex items-center px-8 py-1 gap-2 cursor-pointer">
              <SettingOutlined className="text-xl text-gray-500" />
              <div>Action</div>
              <DownOutlined className="text-gray-400" size={20} />
            </div>
          </Dropdown>
        </div>
      </Col>

      <Col xs={24} md={12}>
        <DetailsCard
          header="Lead Information"
          item={lead}
          fields={[
            { name: 'name' },
            { name: 'email' },
            { name: 'phone' },
            { name: 'company' },
            { name: 'website' },
            { name: 'status', options: LeadStatus },
          ]}
          onSubmit={submitUpdate}
          isSubmitting={updateRequest.isLoading}
        />
      </Col>

      <Col xs={24} md={12}>
        <ActivityCard query={{ lead: id }} />
      </Col>

      {/* <Col xs={24} md={12} lg={8}>
        <FormCard header="Comments"></FormCard>
      </Col> */}
    </Row>
  )
}
