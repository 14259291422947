import {
  createListenerMiddleware,
  createSlice,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit'

const KEY = 'AUTH'
const store = localStorage

export const authSlice = createSlice({
  name: 'auth',
  initialState: store.getItem(KEY),
  reducers: {
    authenticated: (_, a: PayloadAction<string>) => a.payload,
    authCleared: () => null,
  },
})

export const { authenticated, authCleared } = authSlice.actions

export const authPersist = createListenerMiddleware<{ auth: string | null }>()

function isResponse401(payload: unknown): payload is { status: 401 } {
  return typeof payload === 'object' && (payload as any)?.status === 401
}

authPersist.startListening({
  actionCreator: authenticated,
  effect: a => {
    store.setItem(KEY, a.payload)
  },
})

authPersist.startListening({
  actionCreator: authCleared,
  effect: () => store.removeItem(KEY),
})

authPersist.startListening({
  predicate: isRejectedWithValue,
  effect: (action, { dispatch }) => {
    const location = window.location.pathname
    if (isResponse401(action.payload) && location !== '/login') {
      dispatch(authCleared())
      window.location.replace('/login')
    }
  },
})
