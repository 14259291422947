import { MoreOutlined } from '@ant-design/icons'
import { Dropdown, Modal } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import CardGrid from '../components/CardGrid'
import PageTitle from '../components/PageTitle'
import { renderStatus } from '../components/StatusTag'
import Table from '../components/Table'
import TableFilter, { useTableSettings } from '../components/TableFilter'
import { useDeleteOpportunityMutation, useListOpportunitiesQuery } from '../data/api'
import { DealStatus, Opportunity } from '../data/types'

function money(value: unknown): string | undefined {
  if (typeof value === 'string') return money(Number(value))
  if (typeof value !== 'number') return undefined
  return value.toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })
}

export default function OpportunitiesPage() {
  const [deleteOpp] = useDeleteOpportunityMutation()
  const { data: allDeals, isLoading } = useListOpportunitiesQuery()
  const settings = useTableSettings({
    data: allDeals,
    statuses: DealStatus,
    getStatus: deal => deal.status,
    searchable: deal => [deal.subject, deal.company, deal.name],
  })
  const deals = settings.result

  const askDelete = (opp: Opportunity) => {
    Modal.confirm({
      title: 'Do you want to delete this opportunity?',
      onOk: () => deleteOpp(opp.id),
      onCancel() {},
      okButtonProps: { className: 'bg-[#00afef]' },
    })
  }

  const cardRows = (deal: Opportunity) => [
    deal.subject,
    deal.name,
    deal.company,
    deal.value && `₦${deal.value?.toLocaleString()}`,
  ]

  const moreItems = (opp: Opportunity) => [
    {
      key: 'delete',
      label: <div onClick={() => askDelete(opp)}>Delete Opportunity</div>,
    },
  ]

  const columns: ColumnsType<Opportunity> = [
    {
      title: 'Opportunity Name',
      dataIndex: 'subject',
      render: (subject, { id }) => <Link to={`/opportunities/${id}`}>{subject}</Link>,
    },
    { title: 'Company Name', dataIndex: 'company' },
    { title: 'Contact Name', dataIndex: 'name' },
    { title: 'Email', dataIndex: 'email' },
    { title: 'Value', dataIndex: 'value', render: value => money(value) },
    { title: 'Status', dataIndex: 'status', render: renderStatus },
    {
      title: '',
      dataIndex: 'more',
      render: (id, opp) => (
        <Dropdown trigger={['click']} menu={{ items: moreItems(opp) }} placement="bottomRight">
          <MoreOutlined className="m-1" />
        </Dropdown>
      ),
    },
  ]

  return (
    <div className="max-w-screen-xl w-full flex flex-col justify-start overflow-hidden">
      <PageTitle title="Opportunities" className="mb-4" />
      <TableFilter
        settings={settings}
        buttonText="Add Opportunity"
        buttonLink="/opportunities/create"
      />
      {settings.tableMode ? (
        <Table tableColumns={columns} data={deals} loading={isLoading} />
      ) : (
        <CardGrid data={deals} rows={cardRows} status={deal => deal.status} moreItems={moreItems} />
      )}
    </div>
  )
}
