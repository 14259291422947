import { useMemo, useState } from 'react'

export function useSearch<T>(
  allItems: T[] | undefined,
  getText: (item: T) => (string | null | undefined)[]
) {
  const [search, setSearch] = useState('')

  const s = useMemo(() => search.toLowerCase().trim(), [search])
  const words = useMemo(() => s.split(/[^\w-]+/g).map(w => new RegExp(w, 'i')), [s])

  // contains those items that contian every word in the search
  const searched = (allItems ?? []).filter(item => {
    const text = getText(item).filter(Boolean).join(' ')
    return words.every(word => word.test(text))
  })

  return useMemo(() => ({ searched, search, setSearch }), [search, searched])
}
