import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit'
import { Opportunity, Lead, User } from './types'

type MockState = {
  user: User | null
  leads: Lead[]
  deals: Opportunity[]
}

const initialState: MockState = {
  user: null,
  leads: [],
  deals: [],
}

export const mockSlice = createSlice({
  name: 'mock',
  initialState,
  reducers: {
    login: (state, action: PA<User>) => {
      state.user = action.payload
    },
    logout: state => {
      state.user = null
    },
    addLead: (state, action: PA<Lead>) => {
      state.leads.unshift(action.payload)
    },
    addDeal: (state, action: PA<Opportunity>) => {
      state.deals.unshift(action.payload)
    },
  },
})

export const { login, logout, addLead, addDeal } = mockSlice.actions
