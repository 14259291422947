import { Tag } from 'antd'

type TagColor = { text: string; color: string }

const colors: Record<string, TagColor> = {
  default: { text: 'text-gray-700', color: 'default' },
  contacted: { text: 'text-blue-700', color: 'blue' },
  commitment: { text: 'text-blue-700', color: 'blue' },
  negotiation: { text: 'text-blue-700', color: 'blue' },
  won: { text: 'text-green-700', color: '#E0FFEA' },
  lost: { text: 'text-orange-700', color: 'volcano' },
  open: { text: 'text-blue-700', color: 'blue' },
  proposal: { text: 'text-purple-600', color: 'purple' },
  paused: { text: 'text-orange-700', color: '#ffef90' },
}

export default function StatusTag({ status }: { status: string }) {
  const { text, color } = colors[status.toLowerCase()] ?? colors.default
  return (
    <Tag color={color} className="border-0 px-4 py-[6px] rounded-xl">
      <p className={`${text} capitalize font-medium`}>{status}</p>
    </Tag>
  )
}

export function renderStatus(status: string) {
  return <StatusTag status={status} />
}
