import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { authCleared } from '../data/auth'

export function useLogout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return useCallback(() => {
    dispatch(authCleared())
    navigate('/login', { replace: true })
  }, [dispatch, navigate])
}
