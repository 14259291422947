import { DatePicker, Form, Modal } from 'antd'
import { useEffect, useMemo } from 'react'
import { useCreateTaskMutation, useGetMeQuery, useListUsersQuery } from '../data/api'
import { ListTasksQuery, NewTask, TaskType } from '../data/types'
import { useToast } from '../hooks/useToast'
import FormArea from './FormArea'
import FormInput, { inputClass, nameToLabel } from './FormInput'
import FormSelect from './FormSelect'

type Props = {
  type?: TaskType
  visible?: boolean
  onClose?: () => void
  query?: ListTasksQuery
}

export default function AddTaskModal({ type, visible, onClose, query }: Props) {
  const [form] = Form.useForm<NewTask>()
  const [submit, submitState] = useCreateTaskMutation()
  const listUsers = useListUsersQuery()
  const { data: me } = useGetMeQuery()
  const toast = useToast()

  const onSubmit = async (data: NewTask) => {
    try {
      if (!type) return
      const result = await submit({
        ...data,
        type,
        dealId: query?.deal,
        leadId: query?.lead,
      }).unwrap()
      console.log(result)
      form.resetFields()
      onClose?.()
    } catch (e) {
      toast.error(e)
    }
  }

  const users = useMemo(() => {
    return !me ? [] : me.isAdmin || me.isManager ? listUsers.data : [me]
  }, [listUsers.data, me])

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({ type, ownerId: me?.id })
  }, [form, query?.deal, query?.lead, me?.id, type])

  return (
    <Modal
      forceRender
      title={`Add ${nameToLabel(type)}`}
      open={Boolean(visible && type)}
      onOk={() => form.validateFields().then(onSubmit)}
      okText="Add"
      okButtonProps={{ size: 'large' }}
      cancelButtonProps={{ size: 'large' }}
      onCancel={onClose}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        disabled={submitState.isLoading}
        requiredMark="optional"
      >
        <FormInput name="subject" required rules={[{ required: true }]} />
        <FormArea name="description" required rows={3} />
        <FormSelect name="ownerId" label="Owner" options={users} required />
        <FormInput name={type === TaskType.TASK ? 'dueDate' : 'time'} required>
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            showTime={{ format: 'HH:mm' }}
            minuteStep={5}
            allowClear
            name={type === TaskType.TASK ? 'dueDate' : 'time'}
            className={inputClass}
            size="large"
          />
        </FormInput>
      </Form>
    </Modal>
  )
}
