import { ArrowLeftOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import FormArea from '../components/FormArea'
import FormInput from '../components/FormInput'
import FormSelect from '../components/FormSelect'
import { useCreateLeadMutation, useListSourcesQuery } from '../data/api'
import { LeadStatus, NewLead } from '../data/types'
import { useToast } from '../hooks/useToast'
import CustomButton from './../components/CustomButton'
import FormCard from './../components/FormCard'
import PageTitle from './../components/PageTitle'

export default function NewLeadPage() {
  const [submit, { isLoading }] = useCreateLeadMutation()
  const listSources = useListSourcesQuery()
  const navigate = useNavigate()
  const toast = useToast()

  const onSubmit = async (e: any) => {
    const data: NewLead = {
      name: e.name,
      email: e.email,
      phone: e.phone,
      company: e.company,
      website: e.website,
      sourceId: e.sourceId,
      status: e.status || LeadStatus.NEW,
    }
    try {
      const lead = await submit(data).unwrap()
      toast.success(`Lead "${lead.name}" created`)
      navigate(-1)
    } catch (e) {
      toast.error(e)
    }
  }

  return (
    <div className="px-8 w-full max-w-screen-xl">
      <div className="flex gap-4 mb-4">
        <ArrowLeftOutlined className="text-lg cursor-pointer" onClick={() => navigate(-1)} />
        <PageTitle title="Create Lead" />
      </div>
      <Form onFinish={onSubmit} requiredMark="optional">
        <div className="grid gap-8 items-start grid-cols-1 sm:grid-cols-2">
          <FormCard header="Lead Information">
            <FormInput name="name" required />
            <FormInput name="email" />
            <FormInput name="phone" />
            <FormSelect
              allowClear
              name="sourceId"
              label="Source"
              loading={listSources.isLoading}
              options={listSources.data}
            />
            <FormSelect name="status" required options={LeadStatus} defaultValue={LeadStatus.NEW} />
            <FormArea name="description" rows={3} />
          </FormCard>

          <FormCard header="Company Information">
            <FormInput name="company" required />
            <FormInput name="website" />
            <FormInput name="companySize" />
          </FormCard>
        </div>
        <div className="flex items-center justify-center">
          <CustomButton size="large" title="Discard" type="default" />
          <CustomButton
            size="large"
            title="Submit"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          />
        </div>
      </Form>
    </div>
  )
}
