import { Button, ButtonProps } from 'antd'

type CustomButtonProps = ButtonProps

function CustomButton({
  title,
  size,
  type,
  className,
  loading,
  disabled,
  onClick,
  href,
  ...props
}: CustomButtonProps) {
  return (
    <Button
      size={size}
      type={type}
      className={
        `w-[120px] h-[40px] ${type === 'default' && 'text-[#EF5050]'} ${
          type === 'default' && 'border-[#EF5050]'
        } rounded-md m-3 ${type === 'primary' && 'bg-gradient-to-b from-[#00AFEF] to-[#0A96CC]'} 
       ${type === 'primary' && 'hover:bg-[#0A7CA8]'}
       ${type === 'default' && 'hover:border-[#FFE1E1] hover:text-[#FFE1E1]'}
        ` +
        ' ' +
        className
      }
      onClick={onClick}
      {...props}
    >
      {title}
    </Button>
  )
}

export default CustomButton
