import clsx from 'clsx'
import { ReactNode } from 'react'

type CardProps = {
  header: ReactNode
  padded?: boolean
  actions?: React.ReactNode
  children?: React.ReactNode
}

export default function FormCard({ header, children, actions, padded = true }: CardProps) {
  return (
    <div className="rounded-md flex-1 mb-4 border border-gray-300">
      <div className="border-b border-gray-300 flex items-center justify-between px-6 h-12">
        <h1 className="font-medium text-base">{header}</h1>
        {actions && <div className="flex gap-4 items-center">{actions}</div>}
      </div>
      <div className={clsx(padded && 'px-6 pt-6 pb-2')}>{children}</div>
    </div>
  )
}
