import { MoreOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Dropdown, Empty, Modal } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { Link, useNavigate } from 'react-router-dom'
import CardGrid from '../components/CardGrid'
import PageTitle from '../components/PageTitle'
import { renderStatus } from '../components/StatusTag'
import Table, { sorter } from '../components/Table'
import TableFilter, { useTableSettings } from '../components/TableFilter'
import { useDeleteLeadMutation, useListOpenLeadsQuery } from '../data/api'
import { Lead, LeadStatus } from '../data/types'

export default function LeadsPage() {
  const navigate = useNavigate()
  const [deleteLead] = useDeleteLeadMutation()
  const { data: allLeads = [], isLoading } = useListOpenLeadsQuery()

  const settings = useTableSettings({
    data: allLeads,
    statuses: LeadStatus,
    getStatus: lead => lead.status,
    searchable: lead => [lead.name, lead.company, lead.phone],
  })
  const leads = settings.result

  const askDelete = (lead: Lead) => {
    Modal.confirm({
      title: 'Do you want to delete this lead?',
      onOk: () => deleteLead(lead.id),
      onCancel() {},
      okButtonProps: { className: 'bg-[#00afef]' },
    })
  }

  const moreItems = (lead: Lead) => [
    {
      key: 'create-opportunity',
      label: <Link to={`/opportunities/create/${lead.id}`}>Convert to opportunity</Link>,
    },
    { key: 'delete', label: <div onClick={() => askDelete(lead)}>Delete Lead</div> },
  ]

  const columns: ColumnsType<Lead> = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter(lead => lead.name),
      render: (name, { id }) => <Link to={`/leads/${id}`}>{name}</Link>,
    },
    {
      title: 'Company Name',
      dataIndex: 'company',
      sorter: sorter(lead => lead.company),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: sorter(lead => lead.email ?? ''),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: renderStatus,
      sorter: sorter(lead => lead.status),
    },
    {
      title: '',
      dataIndex: 'id',
      render: (_, lead) => (
        <Dropdown trigger={['click']} menu={{ items: moreItems(lead) }} placement="bottomRight">
          <MoreOutlined className="m-1" />
        </Dropdown>
      ),
    },
  ]

  const cardRows = (lead: Lead) => [lead.name, lead.company, lead.website]

  return (
    <div className="max-w-screen-xl w-full flex flex-col justify-start overflow-auto">
      <PageTitle title="Leads" className="mb-4" />

      <TableFilter settings={settings} buttonLink="/leads/create" buttonText="Add Lead" />

      <ConfigProvider
        renderEmpty={() => (
          <Empty description={<span className="text-gray-500 font-semibold">No active leads</span>}>
            <Button
              size="middle"
              className="bg-gradient-to-b from-cyan-500 to-cyan-600 border-none"
              onClick={() => navigate('/leads/create')}
            >
              <span className="text-white">Add Lead</span>
            </Button>
          </Empty>
        )}
      >
        {settings.tableMode ? (
          <Table tableColumns={columns} data={leads} loading={isLoading} />
        ) : (
          <CardGrid
            data={leads}
            rows={cardRows}
            status={lead => lead.status}
            moreItems={moreItems}
          />
        )}
      </ConfigProvider>
    </div>
  )
}
