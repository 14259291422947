import { Form, FormItemProps, Input, InputProps } from 'antd'
import { ReactNode } from 'react'

export const inputClass = 'w-full rounded-md border-gray-300 hover:border-[#00AFEF]'

export const labelProps = {
  labelCol: { span: 8 },
  labelAlign: 'left',
} as const

export function nameToLabel(s?: string) {
  if (!s) return ''
  return s
    .replace(/[\W_]+|(?<=[a-z])(?=[A-Z])/g, ' ')
    .toLowerCase()
    .replace(/\b\w/g, w => w.toUpperCase())
}

export function guessType(name?: string) {
  return /\b(email|phone|password)$/i.exec(name ?? '')?.[0].toLowerCase() ?? 'text'
}

type FormInputProps = InputProps & {
  label?: string
  children?: ReactNode
  rules?: FormItemProps['rules']
}

export default function FormInput({
  name,
  value,
  rules,
  type = guessType(name),
  label = nameToLabel(name),
  required,
  children,
  readOnly,
  ...inputProps
}: FormInputProps) {
  return (
    <Form.Item name={name} label={label} rules={rules} {...labelProps} required={required}>
      {children || (
        <Input
          {...inputProps}
          value={value}
          size="large"
          readOnly={readOnly}
          bordered={!readOnly}
          required={required}
          className={inputClass}
        />
      )}
    </Form.Item>
  )
}
