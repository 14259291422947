import { Avatar, AvatarProps } from 'antd'

type Props = AvatarProps & {
  user?: { name: string }
}

export function initials(name?: string) {
  if (!name) return undefined
  const letters = /^(\w)\S*\s*(\w?)/.exec(name)
  return letters ? (letters[1] + letters[2]).toUpperCase() : undefined
}

export default function UserAvatar({
  user,
  size,
  children = initials(user?.name),
  ...props
}: Props) {
  return (
    <Avatar
      {...props}
      size={size}
      style={{ backgroundColor: '#0a96cc', fontSize: size === 'small' ? 12 : undefined }}
    >
      {children}
    </Avatar>
  )
}
