import { Outlet } from 'react-router-dom'
import UserFooter from './UserFooter'
import UserHeader from './UserHeader'

export default function UserLayout() {
  return (
    <div className="flex flex-col items-stretch min-h-screen">
      <UserHeader />
      <main className="flex flex-col items-center grow p-8">
        <Outlet />
      </main>
      <UserFooter />
    </div>
  )
}
