import { message } from 'antd'

const toastFunctions = {
  error(e: any) {
    console.log(e)
    if (typeof e === 'string') message.error(e)
    else if ('data' in e) message.error(e.data.message)
    else message.error(e?.message ?? 'An unknown error occurred')
  },
  success(msg: string) {
    message.success(msg)
  },
  info(msg: string) {
    message.info(msg)
  },
}

export function useToast() {
  return toastFunctions
}
