import { Table, TableColumnsType } from 'antd'

export function sorter<T>(getter: (t: T) => string) {
  return (a: T, b: T) => {
    const s1 = getter(a)?.toLowerCase() ?? ''
    const s2 = getter(b)?.toLowerCase() ?? ''
    return s1.localeCompare(s2)
  }
}

export function nummSorter<T>(getter: (t: T) => number) {
  return (a: T, b: T) => getter(a) - getter(b)
}

export default function DataTable<T extends { id: string }>(props: {
  tableColumns: TableColumnsType<T> | undefined
  data: readonly T[] | undefined
  noSelect?: boolean
  loading?: boolean
  onClick?: (t: T) => void
}) {
  return (
    <Table<T>
      loading={props.loading}
      columns={props.tableColumns}
      dataSource={props.data}
      rowKey={item => item.id}
      className="border border-gray-100 rounded-xl min-w-max"
      pagination={false}
      rowClassName="text-gray-600"
      rowSelection={props.noSelect ? undefined : { type: 'checkbox' }}
    />
  )
}
