import { AppstoreOutlined, LayoutOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, Popover, Select } from 'antd'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearch } from '../hooks/useSearch'
import { nameToLabel } from './FormInput'

export function useTableSettings<T, S extends string>(args: {
  data: T[] | undefined
  statuses?: Record<string, S>
  getStatus: (t: T) => S
  searchable: (t: T) => (string | null | undefined)[]
}) {
  const { data, statuses, getStatus, searchable } = args
  const { search, setSearch, searched } = useSearch(data, searchable)

  const [tableMode, setTableMode] = useState(true)

  const [filter, setFilter] = useState<S>()
  const filtered = useMemo(() => {
    if (!filter) return searched

    return searched.filter(t => getStatus(t) === filter)
  }, [filter, getStatus, searched])

  const filterOptions = useMemo(() => {
    if (!statuses) return []
    const options = Object.values(statuses).map(s => ({
      label: nameToLabel(s),
      value: s,
    }))
    return [{ label: 'All', value: '' }, ...options]
  }, [statuses])

  const result = filtered

  return useMemo(
    () => ({
      result,
      search,
      filter,
      tableMode,
      setSearch,
      setFilter,
      setTableMode,
      filterOptions,
    }),
    [filter, filterOptions, result, search, setSearch, tableMode]
  )
}

type TableSettings<T, S extends string> = ReturnType<typeof useTableSettings<T, S>>

type Props<T, S extends string> = {
  settings: TableSettings<T, S>
  buttonText: string
  buttonLink: string
}

export default function TableFilter<T, S extends string>({
  settings,
  buttonLink,
  buttonText,
}: Props<T, S>) {
  const LayoutIcon = settings.tableMode ? AppstoreOutlined : LayoutOutlined
  const layoutTitle = settings.tableMode ? 'Switch to cards' : 'Switch to table'

  const navigate = useNavigate()
  return (
    <div className="filter-wrapper flex items-center gap-4 mb-4">
      <div className="grow">
        <Input
          size="large"
          value={settings.search}
          onChange={e => settings.setSearch(e.target.value)}
          prefix={<SearchOutlined className="mr-4 text-gray-300" />}
          className="border border-gray-300 rounded-md"
          placeholder="Search..."
        />
      </div>

      {settings.filterOptions.length > 0 && (
        <Popover title={null} content="Filter by status">
          <Select
            options={settings.filterOptions}
            value={settings.filter}
            onChange={settings.setFilter}
            size="large"
            className="min-w-[150px]"
            placeholder="Filter"
          />
        </Popover>
      )}

      <Popover title={null} content={layoutTitle}>
        <div
          onClick={() => settings.setTableMode(!settings.tableMode)}
          className="layout-wrapper border bg-white border-gray-300 rounded-md flex items-center p-[10px] cursor-pointer"
        >
          <LayoutIcon className="text-gray-500 text-base" />
        </div>
      </Popover>

      <Button
        size="large"
        className="bg-gradient-to-b from-cyan-500 to-cyan-600 border-none"
        onClick={() => navigate(buttonLink)}
      >
        <span className="text-white">{buttonText}</span>
      </Button>
    </div>
  )
}
