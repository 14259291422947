import {
  AlignRightOutlined,
  BellOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
  LockOutlined,
} from '@ant-design/icons'
import { Divider, Drawer, Dropdown } from 'antd'
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import leadsIcon from '../assets/leads.svg'
import appLogo from '../assets/miro-color 1.svg'
import oppIcon from '../assets/oppor.svg'
import { useGetMeQuery } from '../data/api'
import { isElevated } from '../data/roles'
import { useLogout } from '../hooks/useLogout'
import UserAvatar from './UserAvatar'

const menuLinks = [
  { link: '/leads', name: 'Leads', icon: <img src={leadsIcon} alt="" /> },
  { link: '/opportunities', name: 'Opportunities', icon: <img src={oppIcon} alt="" /> },
]

export default function UserHeader() {
  const { data: user } = useGetMeQuery()
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const openDrawer = () => setDrawerOpen(true)
  const closeDrawer = () => setDrawerOpen(false)
  const logout = useLogout()

  const dropdownMenu = [
    {
      key: 'profile',
      label: user && (
        <div className="flex flex-col items-center py-2 gap-2 border-b border-b-gray-200">
          <UserAvatar size="large" user={user} />
          <div className="font-semibold">{user.name}</div>
        </div>
      ),
    },
    {
      key: 'password',
      icon: <LockOutlined />,
      label: <Link to="/change-password">Change password</Link>,
    },
    { key: 'signout', icon: <LogoutOutlined />, label: <div onClick={logout}>Sign out</div> },
  ]

  return (
    <header className="flex shrink-0 h-14 border-b gap-8 md:gap-2 px-9 items-center sm:justify-none">
      <div className="logo my-3">
        <Link to="/leads">
          <img src={appLogo} alt="" />
        </Link>
      </div>

      <nav className="px-4 flex gap-2 md:gap-9 text-[#7C808D] profile">
        {menuLinks.map(({ name, link }) => (
          <NavLink key={name} to={link} className="nav px-1 py-4 text-sm">
            {name}
          </NavLink>
        ))}
      </nav>

      <div className=" w-[20%] flex gap-2 md:gap-4 justify-end profile ml-auto">
        {isElevated(user) && (
          <div className="icon px-1">
            <Link to="/users" title="Users">
              <UserOutlined />
            </Link>
          </div>
        )}
        <div className="icon px-1">
          <SettingOutlined />
        </div>
        <div className="icon px-1">
          <BellOutlined />
        </div>
        <Dropdown menu={{ items: dropdownMenu }} trigger={['click']} className="cursor-pointer">
          <UserAvatar size="small" user={user} />
        </Dropdown>
      </div>

      <div className="flex-end drawer flex justify-middle">
        <AlignRightOutlined
          onClick={openDrawer}
          className="hover:text-[#0A96CC] text-[#7C808D] cursor-pointer text-2xl"
        />

        {/* <img className='hover:text-[#0A96CC] text-[#7C808D] cursor-pointer' src={Burger} alt="" /> */}
      </div>

      <Drawer
        width={250}
        title={
          <div className="flex gap-2 justify-middle py-2">
            <UserAvatar user={user} />
            <span className="text-sm my-1">{user?.name}</span>
          </div>
        }
        placement="right"
        open={isDrawerOpen}
        onClose={closeDrawer}
      >
        {menuLinks.map(({ link, name, icon }) => (
          <p key={name} className="px-2 py-2 menu text-sm" onClick={closeDrawer}>
            <Link key={name} to={link} className="nav flex align-center gap-2">
              {icon}
              {name}
            </Link>
          </p>
        ))}

        <Divider className="border-[0.08rem]" />

        <p className="px-2 py-2 menu" onClick={closeDrawer}>
          <Link to="/" className="nav flex align-center gap-2 text-sm">
            <BellOutlined className="text-xl" />
            Notifications
          </Link>
        </p>

        <p className="px-2 py-2 menu text-sm" onClick={closeDrawer}>
          <Link to="/" className="nav flex align-center gap-2">
            <SettingOutlined className="text-xl" />
            Settings
          </Link>
        </p>

        <p className="px-2 py-2 menu text-sm" onClick={closeDrawer}>
          <Link to="/" className="nav flex align-center gap-2">
            <LogoutOutlined className="text-xl" />
            Sign Out
          </Link>
        </p>
      </Drawer>
    </header>
  )
}
