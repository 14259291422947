import { DownOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps } from 'antd'
import { ReactNode } from 'react'

const colors: Record<string, string> = {
  contacted: 'bg-yellow-500',
  lost: 'bg-orange-700',
  won: 'bg-green-600',
  open: 'bg-purple-600',
  proposal: 'bg-orange-500',
  default: 'bg-gray-500',
}

type Props = {
  rows?: ReactNode[]
  status?: string
  onClick?: () => void
  moreItems?: MenuProps['items']
}

type GridProps<T> = {
  data: T[] | null | undefined
  rows: (t: T) => ReactNode[]
  status?: (t: T) => string
  onClick?: (t: T) => void
  moreItems?: (t: T) => MenuProps['items']
}

export default function CardGrid<T extends { id: string }>(props: GridProps<T>) {
  const { moreItems } = props
  return (
    <ul className="list-none grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      {props.data?.map(item => (
        <Card
          key={item.id}
          rows={props.rows(item)}
          status={props.status?.(item)}
          onClick={() => props.onClick?.(item)}
          moreItems={moreItems?.(item)}
        />
      ))}
    </ul>
  )
}

function Card({ rows, status, moreItems }: Props) {
  const colorKey = status?.toLowerCase() as keyof typeof colors
  const color = (colorKey && colors[colorKey]) || colors.default
  const [header, ...otherRows] = rows || []

  return (
    <li className="bg-gray-50 relative cursor-pointer rounded-xl overflow-hidden">
      <div className="flex flex-col gap-1 py-4 px-6 pr-8">
        {header && <div className="font-500 font-medium">{header}</div>}
        {otherRows.map(row => (
          <div className="text-gray-500 text-sm">{row || '-'}</div>
        ))}
      </div>
      <div>
        <div className={`${color} absolute -right-0.5 -bottom-20 z-10 h-40 w-10 rotate-45`} />
        <Dropdown trigger={['click']} menu={{ items: moreItems }} placement="bottomRight">
          <DownOutlined className="absolute bottom-0.5 right-1 z-20 text-white text-sm m-1" />
        </Dropdown>
      </div>
    </li>
  )
}
