import { Button } from 'antd'
import { FormEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/miro-color 1.svg'
import { apiErrorMessage, useLoginMicrosoftMutation, useLoginMutation } from '../data/api'
import { authenticated } from '../data/auth'
import { useMsalAuth } from '../data/msal'
import { AuthResponse } from '../data/types'
import { useToast } from '../hooks/useToast'

export default function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const success = (auth: AuthResponse) => {
    dispatch(authenticated(auth.token))
    toast.success(`Welcome, ${auth.user.name}`)
    navigate('/', { replace: true })
  }

  // PASSWORD LOGIN

  const [login, loginState] = useLoginMutation()

  const onLoginWithPassword = async (e: FormEvent) => {
    e.preventDefault()
    login({ email, password }).unwrap().then(success).catch(toast.error)
  }

  // MASL LOGIN

  const msal = useMsalAuth()
  const [loginMsal] = useLoginMicrosoftMutation()
  const [isMsalLoading, setMsalLoading] = useState(false)

  const onLoginWithMicrosoft = async () => {
    try {
      setMsalLoading(true)
      const { accessToken } = await msal.login()
      success(await loginMsal({ token: accessToken }).unwrap())
    } catch (e) {
      console.error(e)
      toast.error(apiErrorMessage(e))
    } finally {
      setMsalLoading(false)
    }
  }

  return (
    <div className="flex justify-center items-center bg-gray-100 min-h-screen">
      <section className="flex flex-col items-stretch bg-white rounded-xl p-6 gap-4 w-full max-w-sm m-4">
        <img src={logo} alt="Miro" className="w-24 self-center pt-4" />

        <h1 className="text-base self-center text-center font-medium">
          Customer Relations Management
        </h1>

        <Button
          size="large"
          htmlType="button"
          disabled={loginState.isLoading}
          loading={isMsalLoading}
          onClick={onLoginWithMicrosoft}
          className="bg-blue-500 hover:bg-blue-600 text-white hover:text-white uppercase outline-none"
        >
          Log In with Microsoft
        </Button>

        <div className="relative flex flex-col items-center">
          <hr className="absolute w-full top-1/2" />
          <div className="px-6 py-4 bg-white z-10 text-md font-semibold">OR</div>
        </div>

        <form onSubmit={onLoginWithPassword} className="flex flex-col items-stretch gap-4 w-full">
          <input
            required
            name="email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
            className="border border-gray-300 rounded-lg p-2 text-sm"
          />

          <input
            required
            name="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            className="border border-gray-300 rounded-lg p-2 text-sm"
          />

          <Button
            size="large"
            htmlType="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white uppercase"
          >
            Log In
          </Button>
        </form>
      </section>
    </div>
  )
}
