import {
  CalendarOutlined,
  PhoneOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { Dropdown, Empty, MenuProps, Modal } from 'antd'
import { useMemo, useState } from 'react'
import FormCard from '../components/FormCard'
import { useDeleteTaskMutation, useListTasksQuery, useUpdateTaskMutation } from '../data/api'
import { ListTasksQuery, Task, TaskStatus, TaskType } from '../data/types'
import { useToast } from '../hooks/useToast'
import ActivityCardItem from './ActivityCardItem'
import AddTaskModal from './AddTaskModal'
import ChangeOwnerModal from './ChangeOwnerModal'

type Props = {
  query?: ListTasksQuery
}

const addMenu = (onAdd: (type: TaskType) => void): MenuProps['items'] => [
  {
    key: 'task',
    label: 'Add Task',
    icon: <CalendarOutlined />,
    onClick: () => onAdd(TaskType.TASK),
  },
  {
    key: 'meet',
    label: 'Add Meeting',
    icon: <UsergroupAddOutlined />,
    onClick: () => onAdd(TaskType.MEETING),
  },
  {
    key: 'call',
    label: 'Add Call',
    icon: <PhoneOutlined />,
    onClick: () => onAdd(TaskType.CALL),
  },
]

const statusOrder = {
  [TaskStatus.PENDING]: 0,
  [TaskStatus.COMPLETED]: 1,
  [TaskStatus.PAUSED]: 2,
  [TaskStatus.CANCELED]: 3,
}

export default function ActivityCard(props: Props) {
  const toast = useToast()
  const listTasks = useListTasksQuery(props.query)
  const tasks = useMemo(() => {
    if (!listTasks.data?.length) return []

    return [...listTasks.data].sort((a, b) => {
      const cmp = statusOrder[a.status] - statusOrder[b.status]
      if (cmp !== 0) return cmp

      const t1 = a.dueDate || a.time
      const t2 = b.dueDate || b.time
      // if both have times, order by time
      if (t1 && t2) return t1.localeCompare(t2)

      // if one has time, put it first
      if (t1) return -1
      if (t2) return +1

      // if neither have time, put newest first
      return b.createdAt.localeCompare(a.createdAt)
    })
  }, [listTasks.data])

  const updateTaskMutation = useUpdateTaskMutation()
  const [changeOwnerForTask, setChangeOwnerForTask] = useState<Task>()

  const [typeToAdd, setTypeToAdd] = useState<TaskType>()
  const [updateTask] = useUpdateTaskMutation()
  const [deleteTask] = useDeleteTaskMutation()
  const [deleteList, setDeleteList] = useState<string[]>([])
  const isDeleting = (task: Task) => deleteList.includes(task.id)

  const onStatus = (task: Task, status: TaskStatus) => {
    Modal.confirm({
      onOk: () => updateTask({ id: task.id, status }),
      title: `Update Status`,
      content: `This ${task.type.toLowerCase()} will be marked as ${status.toLowerCase()}.`,
      okText: 'Update',
      cancelText: 'Cancel',
    })
  }

  const onDelete = (task: Task) => {
    Modal.confirm({
      onOk: async () => {
        setDeleteList(d => [...d, task.id])
        deleteTask(task.id)
          .unwrap()
          .catch(e => toast.error(e))
          .finally(() => setDeleteList(d => d.filter(id => id !== task.id)))
      },
      title: `Delete ${task.type.toLowerCase()}`,
      content: `This ${task.type.toLowerCase()} will be deleted.`,
      okText: 'Delete',
      cancelText: 'Cancel',
    })
  }

  const taskMenu = (task: Task): MenuProps['items'] => [
    task.status === TaskStatus.PENDING
      ? {
          key: 'status',
          label: 'Mark as completed',
          onClick: () => onStatus(task, TaskStatus.COMPLETED),
        }
      : {
          key: 'status',
          label: `Reopen ${task.type.toLowerCase()}`,
          onClick: () => onStatus(task, TaskStatus.PENDING),
        },
    {
      key: 'owner',
      label: 'Change owner',
      onClick: () => setChangeOwnerForTask(task),
    },
    {
      key: 'delete',
      label: `Delete ${task.type.toLowerCase()}`,
      onClick: () => onDelete(task),
    },
  ]

  return (
    <FormCard
      header="Activity"
      padded={false}
      actions={
        <>
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            menu={{ items: addMenu(setTypeToAdd) }}
          >
            <PlusOutlined className="px-1 text-lg hover:text-[#00afef]" />
          </Dropdown>
        </>
      }
    >
      <AddTaskModal
        type={typeToAdd}
        visible={Boolean(typeToAdd)}
        onClose={() => setTypeToAdd(undefined)}
        query={props.query}
      />

      <ChangeOwnerModal
        type={changeOwnerForTask?.type}
        mutation={updateTaskMutation}
        itemId={changeOwnerForTask?.id}
        visible={Boolean(changeOwnerForTask)}
        onClose={() => setChangeOwnerForTask(undefined)}
        currentOwnerId={changeOwnerForTask?.owner?.id}
      />

      <div className="max-h-[60vh] overflow-y-auto">
        {tasks?.length ? (
          tasks?.map(task => (
            <ActivityCardItem
              key={task.id}
              task={task}
              taskMenu={taskMenu(task)}
              disabled={isDeleting(task)}
            />
          ))
        ) : (
          <Empty description="No tasks added yet" className="my-8" />
        )}
      </div>
    </FormCard>
  )
}
