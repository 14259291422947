import { Button, Form, InputNumber } from 'antd'
import { ReactNode, useEffect, useState } from 'react'
import { canModify, useGetMeQuery } from '../data/api'
import { useToast } from '../hooks/useToast'
import FormCard from './FormCard'
import FormInput, { inputClass, nameToLabel } from './FormInput'
import FormSelect, { FormSelectOptions } from './FormSelect'

type Item = {
  id: string
  owner: { id: string }
} & { [key in string]?: unknown }

type Field<T> = {
  name: string & keyof T
  label?: string
  options?: FormSelectOptions
  isMoney?: boolean
}

type Props<T> = {
  fields: Field<T>[]
  header: ReactNode
  item?: T
  onSubmit?: (t: Partial<T>) => { unwrap(): Promise<any> }
  isSubmitting?: boolean
}

export default function DetailsCard<T extends Item>({
  fields,
  header,
  item,
  onSubmit,
  isSubmitting,
}: Props<T>) {
  const [editing, setEditing] = useState(false)
  const [form] = Form.useForm()
  const toast = useToast()

  const { data: me } = useGetMeQuery()
  const canEdit = canModify(me, item)

  const onEdit = () => {
    if (!canEdit) return
    console.log('onEdit')
    setEditing(true)
  }

  const onCancel = () => {
    setEditing(false)
    form.setFieldsValue(item)
  }

  const onSave = (data: Partial<T>) => {
    if (!item) return setEditing(false)

    const request = onSubmit?.({ id: item.id, ...data })
    if (!request) return
    request
      .unwrap()
      .then(() => {
        console.log('Done')
        toast.success('Changes saved')
        setEditing(false)
      })
      .catch(e => {
        console.error(e)
        toast.error(e)
      })
  }

  useEffect(() => {
    if (item) form.setFieldsValue(item)
  }, [form, item])

  const actions = (
    <>
      {editing && (
        <>
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>
            Save
          </Button>
          <Button
            htmlType="button"
            className="text-red-600"
            disabled={isSubmitting}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </>
      )}

      {!editing && canEdit && (
        <Button htmlType="button" onClick={onEdit}>
          Edit
        </Button>
      )}
    </>
  )

  const inputProps = (field: Field<T>) => {
    return {
      name: editing ? field.name : undefined,
      label: field.label || nameToLabel(field.name),
      // when editing, take the value from the form, otherwise set it here
      value: editing
        ? undefined
        : field.isMoney
        ? `₦ ${item?.[field.name]}`
        : (item?.[field.name] as any),
      readOnly: !editing,
      disabled: isSubmitting,
    }
  }

  return (
    <Form form={form} onFinish={onSave}>
      <FormCard header={header} actions={actions}>
        {fields.map(f =>
          f.options ? (
            <FormSelect key={f.name} {...inputProps(f)} options={f.options} />
          ) : f.isMoney && editing ? (
            <FormInput key={f.name} {...inputProps(f)}>
              <InputNumber
                required
                {...inputProps(f)}
                size="large"
                className={inputClass}
                formatter={value => `₦ ${value}`}
              />
            </FormInput>
          ) : (
            <FormInput key={f.name} {...inputProps(f)} />
          )
        )}
      </FormCard>
    </Form>
  )
}
