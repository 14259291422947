import {
  CalendarOutlined,
  MoreOutlined,
  PhoneOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { Dropdown, MenuProps } from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { nameToLabel } from '../components/FormInput'
import { Task, TaskStatus, TaskType } from '../data/types'

function TaskIcon(props: { type: TaskType; className?: string }) {
  const Icon =
    props.type === TaskType.MEETING
      ? UsergroupAddOutlined
      : props.type === TaskType.CALL
      ? PhoneOutlined
      : CalendarOutlined
  return <Icon className={props.className} />
}

type Props = {
  task: Task
  taskMenu: MenuProps['items']
  disabled?: boolean
}

function showTime(task: Task) {
  const t = task.dueDate || task.time
  if (!t) return null

  const d = dayjs(t)
  if (d.isToday()) return d.format('hh:mm a')

  if (d.year() === dayjs().year()) return d.format('D MMM')

  return d.format('D MMM YYYY')
}

export default function ActivityCardItem({ task, taskMenu, disabled }: Props) {
  const time = useMemo(() => showTime(task), [task])

  return (
    <div className="flex gap-5 items-center px-6 py-3 border-t first:border-none border-gray-300">
      <div
        className={clsx({
          'rounded-full flex-shrink-0 w-10 h-10 flex justify-center items-center': true,
          'bg-[#00afff]': task.status === TaskStatus.PENDING,
          'bg-green-500': task.status === TaskStatus.COMPLETED,
          'bg-red-600': task.status === TaskStatus.CANCELED,
          'bg-gray-600': task.status === TaskStatus.PAUSED,
          'bg-gray-500': disabled,
        })}
      >
        <TaskIcon type={task.type} className="text-lg text-white" />
      </div>
      <div className="flex-grow">
        <div>
          <span className="font-medium">{task.subject}</span>
          <span className="text-gray-500">
            <span className="px-1.5">•</span>
            {task.owner.name}
          </span>
        </div>

        {task.description && <div className="text-gray-400 text-xs my-0.5">{task.description}</div>}

        <div
          className={clsx(
            {
              'text-cyan-600': task.status === TaskStatus.PENDING,
              'text-green-600': task.status === TaskStatus.COMPLETED,
              'text-red-600': task.status === TaskStatus.CANCELED,
              'text-gray-500': disabled,
            },
            'text-xs'
          )}
        >
          {nameToLabel(task.status)} {nameToLabel(task.type)}
        </div>
      </div>
      <div className="flex-shrink-0 flex items-center gap-2">
        {time && <div>{time}</div>}

        <Dropdown
          trigger={['click']}
          menu={{ items: taskMenu }}
          placement="bottomRight"
          disabled={disabled}
        >
          <MoreOutlined className="text-lg p-1" />
        </Dropdown>
      </div>
    </div>
  )
}
