import { Modal, Select } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useGetMeQuery, useListUsersQuery } from '../data/api'
import { useToast } from '../hooks/useToast'

type Data = { id: string; ownerId: string }
type Submit = (data: Data) => { unwrap: () => Promise<any> }
type Mutation = readonly [Submit, { isLoading: boolean }]

type Props = {
  type?: string
  itemId?: string
  visible?: boolean
  mutation: Mutation
  onClose?: () => void
  currentOwnerId?: string
}

export default function ChangeOwnerModal({
  type,
  itemId,
  visible,
  mutation,
  onClose,
  currentOwnerId,
}: Props) {
  const [ownerId, setOwnerId] = useState(currentOwnerId)
  const [submit, submitState] = mutation
  const { data: me } = useGetMeQuery()
  const listUsers = useListUsersQuery()
  const toast = useToast()

  useEffect(() => setOwnerId(currentOwnerId), [currentOwnerId])

  const onSubmit = async () => {
    try {
      if (!itemId || !ownerId) return
      if (ownerId === currentOwnerId) return onClose?.()

      const result = await submit({ id: itemId, ownerId }).unwrap()
      console.log(result)
      setOwnerId(undefined)
      onClose?.()
    } catch (e) {
      toast.error(e)
    }
  }

  const usersOptions = useMemo(() => {
    if (!me) return []
    // only managers and admins can set someon else as the owner of a task
    const users = me.isAdmin || me.isManager ? listUsers.data : [me]
    return users?.map(user => ({ label: user.name, value: user.id }))
  }, [listUsers.data, me])

  return (
    <Modal
      title={`Change the owner of this ${type?.toLowerCase() || 'item'}`}
      open={Boolean(visible && itemId)}
      confirmLoading={submitState.isLoading}
      okText="Change"
      okButtonProps={{ size: 'large', disabled: !itemId || !ownerId }}
      cancelButtonProps={{ size: 'large' }}
      onOk={onSubmit}
      onCancel={onClose}
    >
      <Select
        size="large"
        className="w-full my-2"
        options={usersOptions}
        value={ownerId}
        onChange={setOwnerId}
      />
    </Modal>
  )
}
