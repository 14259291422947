import { Form, Select, SelectProps } from 'antd'
import id from 'date-fns/esm/locale/id/index.js'
import { useMemo } from 'react'
import FormInput, { inputClass, labelProps, nameToLabel } from './FormInput'

export type FormSelectOption =
  | string
  | number
  | { id: string; name: string }
  | { id: string; title: string }

export type FormSelectOptions = FormSelectOption[] | Record<string, FormSelectOption>

type FormSelectProps = Omit<SelectProps, 'options'> & {
  name?: string
  label?: string
  required?: boolean
  readOnly?: boolean
  options?: FormSelectOption[] | Record<string, FormSelectOption>
}

function valueOf(item: FormSelectOption) {
  return typeof item === 'object' ? item.id : item.toString()
}

function labelOf(item: FormSelectOption) {
  if (typeof item !== 'object') return item.toString()
  return nameToLabel('title' in item ? item.title : item.name)
}

export default function FormSelect({
  name,
  label = nameToLabel(name),
  required,
  readOnly,
  options,
  ...props
}: FormSelectProps) {
  const optionValues = useMemo(() => {
    return !options || Array.isArray(options) ? options : Object.values(options)
  }, [options])

  const selectOptions = useMemo(() => {
    if (readOnly || !optionValues) return undefined
    return optionValues.map(o => ({ label: labelOf(o), value: valueOf(o) }))
  }, [optionValues, readOnly])

  if (readOnly) {
    const { defaultValue, value = defaultValue } = props
    const option = optionValues?.find(o => valueOf(o) === valueOf(value))
    const text = option ? labelOf(option) : value
    return <FormInput label={label} readOnly name={name} value={text} />
  }

  return (
    <Form.Item name={name} label={label} required={required} {...labelProps}>
      <Select {...props} size="large" className={inputClass} options={selectOptions} />
    </Form.Item>
  )
}
