import { Form, InputNumber } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FormInput, { inputClass } from '../components/FormInput'
import FormSelect from '../components/FormSelect'
import { useCreateOpportunityMutation, useListLeadsQuery } from '../data/api'
import { DealStatus, NewOpportunity } from '../data/types'
import { useToast } from '../hooks/useToast'
import CustomButton from './../components/CustomButton'
import FormCard from './../components/FormCard'
import PageTitle from './../components/PageTitle'

export default function NewOpportunityPage() {
  const { id } = useParams<{ id: string }>()
  const [form] = Form.useForm<NewOpportunity>()
  const [submit, { isLoading }] = useCreateOpportunityMutation()
  const { data: allLeads = [] } = useListLeadsQuery()
  const [leadId, setLeadId] = useState<string | undefined>(id)
  const navigate = useNavigate()
  const toast = useToast()

  const lead = useMemo(() => {
    return leadId ? allLeads.find(lead => lead.id === leadId) : undefined
  }, [allLeads, leadId])

  useEffect(() => {
    // if leadId is given but lead does not exist, remove leadId
    if (!lead && allLeads && leadId) {
      setLeadId(undefined)
    }
  }, [allLeads, lead, leadId])

  useEffect(() => {
    if (!lead) return

    // if lead is given, set the defaults
    form.setFieldsValue({
      address: lead.address,
      email: lead.email,
      phone: lead.phone,
      name: lead.name,
      company: lead.company,
      website: lead.website,
    })
  }, [form, lead])

  const onSubmit = async (e: any) => {
    const data: NewOpportunity = {
      subject: e.subject,
      name: e.name,
      product: e.product,
      value: e.value,
      leadId,
      email: e.email,
      phone: e.phone,
      company: e.company,
      status: e.status || DealStatus.OPEN,
    }

    try {
      const opp = await submit(data).unwrap()
      toast.success(`Opportunity "${opp.subject}" created`)
      navigate('/opportunities', { replace: true })
    } catch (e) {
      toast.error(e)
    }
  }
  return (
    <div className="px-6 w-full max-w-screen-xl mx-auto">
      <Form
        form={form}
        onFinish={onSubmit}
        requiredMark="optional"
        className="flex flex-col items-stat gap-4"
      >
        <PageTitle title="Create Opportunity" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
          <FormCard header="Opportunity Information">
            <FormInput name="subject" label="Opportunity Name" required />
            <FormInput name="product" label="Product of interest" required />
            <FormInput name="value" required>
              <InputNumber
                required
                name="value"
                size="large"
                className={inputClass}
                formatter={value => `₦ ${value}`}
              />
            </FormInput>

            <FormSelect
              name="status"
              required
              options={DealStatus}
              defaultValue={DealStatus.OPEN}
            />
          </FormCard>

          <FormCard header="Contact Information">
            <FormInput name="name" label="Contact Name" required />
            <FormInput name="email" />
            <FormInput name="phone" />
            <FormInput name="company" required />
            <FormInput name="website" />
            <FormInput name="address" />

            <FormSelect
              allowClear
              label="Related to Lead"
              value={leadId || ''}
              onChange={setLeadId}
              placeholder="None"
              options={allLeads}
            />
          </FormCard>
        </div>
        <div className="flex items-center justify-center mb-24">
          <CustomButton size="large" title="Discard" type="default" loading={isLoading} />
          <CustomButton size="large" title="Submit" type="primary" htmlType="submit" />
        </div>
      </Form>
    </div>
  )
}
