import { Form, FormItemProps, Input } from 'antd'
import type { TextAreaProps } from 'antd/es/input'
import clsx from 'clsx'
import { inputClass, labelProps, nameToLabel } from './FormInput'

type FormAreaProps = TextAreaProps & {
  label?: string
  rules?: FormItemProps['rules']
}

export default function FormArea({
  name,
  rules,
  label = nameToLabel(name),
  required,
  ...inputProps
}: FormAreaProps) {
  return (
    <Form.Item name={name} label={label} rules={rules} {...labelProps} required={required}>
      <Input.TextArea
        {...inputProps}
        size="large"
        required={required}
        className={clsx(inputClass, 'p-2')}
      />
    </Form.Item>
  )
}
