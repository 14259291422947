import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import DataProvider from './data'
import './index.css'
import reportWebVitals from './reportWebVitals'

dayjs.extend(isToday)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <DataProvider>
      <App />
    </DataProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
