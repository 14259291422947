import { MsalProvider } from '@azure/msal-react'
import React from 'react'
import { Provider } from 'react-redux'
import { msalInstance } from './msal'
import { store } from './store'

export default function DataProvider(props: { children: React.ReactNode }) {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>{props.children}</Provider>
    </MsalProvider>
  )
}
