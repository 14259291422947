import { ConfigProvider } from 'antd'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './App.css'
import UserLayout from './components/UserLayout'
import ChangePasswordPage from './pages/ChangePasswordPage'
import HomePage from './pages/HomePage'
import LeadDetailPage from './pages/LeadDetailPage'
import LeadsPage from './pages/LeadsPage'
import LoginPage from './pages/LoginPage'
import NewLeadPage from './pages/NewLeadPage'
import NewOpportunityPage from './pages/NewOpportunityPage'
import NotFoundPage from './pages/NotFoundPage'
import OpportunitiesPage from './pages/OpportunitiesPage'
import OpportunityDetailPage from './pages/OpportunityDetailPage'
import UsersPage from './pages/UsersPage'

const router = createBrowserRouter([
  { path: '/login', element: <LoginPage /> },
  { path: '/change-password', element: <ChangePasswordPage /> },
  {
    path: '/',
    element: <UserLayout />,
    errorElement: <NotFoundPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: '/leads', element: <LeadsPage /> },
      { path: '/leads/:id', element: <LeadDetailPage /> },
      { path: '/opportunities', element: <OpportunitiesPage /> },
      { path: '/opportunities/:id', element: <OpportunityDetailPage /> },
      { path: '/leads/create', element: <NewLeadPage /> },
      { path: '/opportunities/create', element: <NewOpportunityPage /> },
      { path: '/opportunities/create/:id', element: <NewOpportunityPage /> },
      { path: '/users', element: <UsersPage /> },
    ],
  },
])

export default function App() {
  return (
    <ConfigProvider componentSize="small" theme={{}}>
      <RouterProvider router={router} />
      <ToastContainer />
    </ConfigProvider>
  )
}
