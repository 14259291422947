import { Button } from 'antd'
import { FormEvent, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/miro-color 1.svg'
import { useChangePasswordMutation } from '../data/api'
import { useToast } from '../hooks/useToast'

export default function ChangePasswordPage() {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [send, sendRequest] = useChangePasswordMutation()

  const navigate = useNavigate()
  const toast = useToast()

  const success = () => {
    toast.success(`Password changed`)
    navigate('/', { replace: true })
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (newPassword !== confirmPassword) return toast.error('Passwords do not match')
    send({ oldPassword, newPassword, confirmPassword }).unwrap().then(success).catch(toast.error)
  }

  const passwordsDoNotMatch = useMemo(
    () => Boolean(newPassword && confirmPassword && newPassword !== confirmPassword),
    [confirmPassword, newPassword]
  )

  return (
    <div className="flex justify-center items-center bg-gray-100 min-h-screen">
      <section className="flex flex-col items-stretch bg-white rounded-xl p-6 gap-4 w-full max-w-sm m-4">
        <button onClick={() => navigate(-1)} className="cursor-pointer self-start">
          ⟵ Go back
        </button>

        <img src={logo} alt="Miro" className="w-24 self-center pt-4" />

        <h1 className="text-base self-center text-center font-medium">Change your password</h1>

        <form onSubmit={onSubmit} className="flex flex-col items-stretch gap-4 w-full">
          <input
            required
            type="password"
            name="oldPassword"
            placeholder="Current Password"
            value={oldPassword}
            onChange={e => setOldPassword(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 text-sm"
          />

          <input
            required
            type="password"
            name="oldPassword"
            placeholder="New password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 text-sm"
          />

          <input
            required
            type="password"
            name="oldPassword"
            placeholder="Confirm your new password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 text-sm"
          />

          {passwordsDoNotMatch && <div className="text-red-500">Passwords do not match</div>}

          <Button
            size="large"
            htmlType="submit"
            loading={sendRequest.isLoading}
            className="bg-blue-500 hover:bg-blue-600 text-white uppercase"
          >
            Submit
          </Button>
        </form>
      </section>
    </div>
  )
}
