import { ArrowLeftOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Col, Dropdown, Modal, Row, Spin } from 'antd'
import { useMemo, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router'
import ActivityCard from '../components/ActivityCard'
import ChangeOwnerModal from '../components/ChangeOwnerModal'
import DetailsCard from '../components/DetailsCard'
import { nameToLabel } from '../components/FormInput'
import UserAvatar from '../components/UserAvatar'
import {
  useListOpportunitiesQuery,
  useSetOpportunityOwnerMutation,
  useSetOpportunityStatusMutation,
  useUpdateOpportunityMutation,
} from '../data/api'
import { DealStatus } from '../data/types'

export default function OpportunityDetailPage() {
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()
  const { data: allOpps, isLoading } = useListOpportunitiesQuery()
  const [submitUpdate, updateRequest] = useUpdateOpportunityMutation()
  const [submitStatus, statusRequest] = useSetOpportunityStatusMutation()
  const opportunity = useMemo(() => allOpps?.find(lead => lead.id === id), [allOpps, id])

  const [changingOwner, setChangingOwner] = useState(false)
  const setOwnerMutation = useSetOpportunityOwnerMutation()

  const setStatus = async (status: DealStatus) => {
    if (opportunity) submitStatus({ id: opportunity.id, status })
  }

  if (isLoading) {
    return <Spin size="large" className="m-auto" />
  }
  if (!opportunity) {
    return <Navigate to="/leads" replace />
  }

  return (
    <div className="w-full max-w-screen-xl px-8 mx-auto">
      <ChangeOwnerModal
        type="lead"
        mutation={setOwnerMutation}
        itemId={opportunity?.id}
        visible={changingOwner}
        onClose={() => setChangingOwner(false)}
        currentOwnerId={opportunity?.owner?.id}
      />
      <Row className="w-full my-4" gutter={16}>
        <Col xs={24} className="flex justify-between items-center mb-4">
          <div className="flex gap-4 items-center">
            <ArrowLeftOutlined className="text-2xl cursor-pointer" onClick={() => navigate(-1)} />
            <UserAvatar user={opportunity} size="large" />
            <div>
              <div className="font-medium text-xl leading-snug">{opportunity.name}</div>
              <div className="text-sm">Opportunity</div>
            </div>
          </div>

          <div className="flex items-center">
            {/* <div className="px-8 py-1 border-r border-gray-300">
              <div className="font-medium leading-snug">0</div>
              <div className="text-xs text-gray-400">Meetings</div>
            </div> */}

            <Dropdown
              trigger={['click']}
              disabled={statusRequest.isLoading}
              menu={{
                items: Object.values(DealStatus).map(status => ({
                  key: status,
                  label: nameToLabel(status),
                  onClick: () =>
                    Modal.confirm({
                      icon: <InfoCircleOutlined />,
                      title: `Change status to ${nameToLabel(status)}?`,
                      onOk: () => setStatus(status),
                      okButtonProps: { className: 'bg-[#00afef]' },
                    }),
                })),
              }}
            >
              <div className="flex items-center px-8 py-1 gap-2 border-r border-gray-300 cursor-pointer">
                <div>
                  <div className="font-medium leading-snug">{nameToLabel(opportunity.status)}</div>
                  <div className="text-xs text-gray-400">Status</div>
                </div>
                {statusRequest.isLoading ? (
                  <Spin size="small" />
                ) : (
                  <DownOutlined className="text-gray-400 ml-2" size={20} />
                )}
              </div>
            </Dropdown>

            <div
              className="flex items-center px-8 py-1 gap-2 cursor-pointer"
              onClick={() => setChangingOwner(true)}
            >
              <UserAvatar user={opportunity.owner} />
              <div>
                <div className="font-medium leading-snug">{opportunity.owner.name}</div>
                <div className="text-xs text-gray-400">Owner</div>
              </div>
              <DownOutlined className="text-gray-400 ml-2" size={20} />
            </div>
          </div>
        </Col>

        <Col xs={24} md={12}>
          <DetailsCard
            header="Opportunity Information"
            item={opportunity}
            fields={[
              { name: 'subject', label: 'Opportunity Name' },
              { name: 'name', label: 'Contact Name' },
              { name: 'product' },
              { name: 'value', isMoney: true },
              { name: 'phone' },
              { name: 'email' },
              { name: 'company', label: 'Company Name' },
              { name: 'website' },
              { name: 'address' },
            ]}
            onSubmit={submitUpdate}
            isSubmitting={updateRequest.isLoading}
          />
        </Col>

        <Col xs={24} md={12}>
          <ActivityCard query={{ deal: id }} />
        </Col>

        {/* <Col xs={24} md={12}>
          <FormCard header="Comments"></FormCard>
        </Col> */}
      </Row>
    </div>
  )
}
