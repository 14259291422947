import { MoreOutlined } from '@ant-design/icons'
import { Dropdown, Form } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { Navigate } from 'react-router-dom'
import CustomButton from '../components/CustomButton'
import FormCard from '../components/FormCard'
import FormInput from '../components/FormInput'
import FormSelect from '../components/FormSelect'
import PageTitle from '../components/PageTitle'
import DataTable, { nummSorter, sorter } from '../components/Table'
import { useCreateUserMutation, useGetMeQuery, useListUsersQuery } from '../data/api'
import { isElevated } from '../data/roles'
import { NewUser, User } from '../data/types'
import { useToast } from '../hooks/useToast'

export default function UsersPage() {
  const { data: me } = useGetMeQuery()
  const users = useListUsersQuery()
  const [submitCreate, { isLoading: isCreating }] = useCreateUserMutation()
  const [form] = Form.useForm()
  const toast = useToast()

  const addUser = async (e: any) => {
    const data: NewUser = {
      name: e.name,
      email: e.email,
      isManager: e.role === 'manager',
    }
    try {
      const lead = await submitCreate(data).unwrap()
      toast.success(`${lead.name} has been added`)
      form.resetFields()
    } catch (e) {
      toast.error(e)
    }
  }

  const moreItems = (user: User) => [
    { key: 'edit', label: <div>Edit User</div> },
    { key: 'remove', label: <div>Remove user</div> },
  ]

  const columns: ColumnsType<User> = [
    {
      title: 'User Name',
      dataIndex: 'name',
      sorter: sorter(user => user.name),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: sorter(user => user.email),
    },
    {
      title: 'Role',
      render: (_, user) => (user.isAdmin ? 'Admin' : user.isManager ? 'Manager' : 'Employee'),
      sorter: nummSorter(user => (user.isAdmin ? 0 : user.isManager ? 1 : 2)),
    },
    {
      width: 50,
      title: '',
      dataIndex: 'id',
      render: (_, lead) => (
        <Dropdown trigger={['click']} menu={{ items: moreItems(lead) }} placement="bottomRight">
          <MoreOutlined className="m-1" />
        </Dropdown>
      ),
    },
  ]

  if (!me) return null
  if (!isElevated(me)) return <Navigate to="/" replace />

  return (
    <div className="max-w-screen-xl w-full flex flex-col justify-start overflow-auto">
      <PageTitle title="Users" className="mb-4" />
      <div className="grid gap-4 grid-cols-3">
        <div className="col-span-3 lg:col-span-2">
          <DataTable noSelect tableColumns={columns} loading={users.isLoading} data={users.data} />
        </div>
        <Form
          form={form}
          onFinish={addUser}
          className="col-span-3 lg:col-span-1"
          requiredMark={false}
          initialValues={{ role: 'user' }}
        >
          <FormCard header="Add User">
            <FormInput name="name" required />
            <FormInput name="email" required />
            <FormSelect name="role" options={['user', 'manager']} />
            <div className="flex justify-end mb-2">
              <CustomButton
                size="middle"
                title="Add user"
                type="primary"
                htmlType="submit"
                className="m-0"
                loading={isCreating}
              />
            </div>
          </FormCard>
        </Form>
      </div>
    </div>
  )
}
