import React from 'react'

type TitleProps = {
  title: string
  className?: string
}

function PageTitle({ title, className }: TitleProps) {
  return <h1 className={`font-medium text-xl text-[#37383C] ${className ?? ''}`}>{title}</h1>
}

export default PageTitle
